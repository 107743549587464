import React, { useEffect, useState } from 'react';
import UserMessage from '../UserMessage';
import BotMessage from '../BotMessage';
import Input from '../Input';
import API from "../../ChatbotAPI";
import { v4 as uuidv4 } from 'uuid';
import Messages from '../Messages';

export const BanditBotOffCanvasTBS = ({handleClose,userId,mailId}) => {
 
  const [messages, setMessages] = useState([]);
  const [chatbotVisible, setChatbotVisible] = useState(false);
  const [defaultMsg, setDefaultMsg] = useState("");
  const [uuid, setUuid] = useState();

  const loadWelcomeMessage = async () => {

    setMessages([
      <BotMessage
        key="0"
        fetchMessage={async () => await API.GetChatbotResponse("start", uuid, userId,mailId)}
        init={true}
        setDefaultMsg={setDefaultMsg}
      />
    ]);
  };

  useEffect(() => {
    setUuid(uuidv4());
  }, []);
 
  
  useEffect(() => {
    send(defaultMsg);
  }, [defaultMsg]);

  useEffect(() => {
    loadWelcomeMessage();
  }, [chatbotVisible]);

  const send = async text => {

    const newMessages = messages.concat(
      <UserMessage key={messages?.length + 1} text={text} />,
      <BotMessage
        key={messages?.length + 2}
        fetchMessage={async () => await API.GetChatbotResponse(text, uuid,userId,mailId)}
        init={false}
        setDefaultMsg={setDefaultMsg}
      />
    );
    setMessages(newMessages);

  }

  return (
    <div className={`offcanvas offcanvas-end offcanvas-width`} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" data-bs-backdrop="false">
      <div className="offcanvas-header py-1 px-3" onClick={handleClose}>
        <h5 className="offcanvas-title fs-5" id="offcanvasExampleLabel">{process.env.REACT_APP_SITE_NAME}</h5>
        <button type="button" className="btn p-0" data-bs-dismiss="offcanvas" aria-label="Close" ><i className='mdi mdi-close-circle fs-3 text-white lh-sm' ></i></button>
      </div>
      <div className="offcanvas-body">
        <Messages messages={messages} />
      </div>

      <div className="offcanvas-footer p-0">
        <Input onSend={send} />
      </div>
    </div>
    
  );
}