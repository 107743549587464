import React from 'react'
import { BanditBotOffCanvasTBS } from './BanditBotOffCanvas'
export const BanditBot = ({handleClose,userId,mailId}) => {
  return (
    <>
      <BanditBotOffCanvasTBS
       handleClose={handleClose}
       userId={userId}
       mailId={mailId}
      />
    </>
  )
}
